.pac-item {
  cursor: pointer;
  font-size: medium;
  padding-top: 10px;
  padding-bottom: 5px;
  border-top: 0;
}

.pac-container {
  z-index: 105100 !important;
  margin-top: 6px;
  border: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .pac-container {
    box-shadow: none;
  }
}
