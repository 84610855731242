/* @import url('https://db.onlinewebfonts.com/c/13a7a4a64f0a4b39a872e732c91d2375?family=FuturaPT-Book'); */
@font-face {
  font-family: 'FuturaPT-Book';
  src: url('https://db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.eot');
  src: url('https://db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.eot?#iefix') format('embedded-opentype'),
    url('https://db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.woff2') format('woff2'),
    url('https://db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.woff') format('woff'),
    url('https://db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.ttf') format('truetype'),
    url('https://db.onlinewebfonts.com/t/13a7a4a64f0a4b39a872e732c91d2375.svg#FuturaPT-Book') format('svg');
}

/* @import url('https://db.onlinewebfonts.com/c/5bdac91b83b25ea168302ffbc40909de?family=Futura+PT+Demi'); */
@font-face {
  font-family: 'Futura PT Demi';
  src: url('https://db.onlinewebfonts.com/t/5bdac91b83b25ea168302ffbc40909de.eot');
  src: url('https://db.onlinewebfonts.com/t/5bdac91b83b25ea168302ffbc40909de.eot?#iefix') format('embedded-opentype'),
    url('https://db.onlinewebfonts.com/t/5bdac91b83b25ea168302ffbc40909de.woff2') format('woff2'),
    url('https://db.onlinewebfonts.com/t/5bdac91b83b25ea168302ffbc40909de.woff') format('woff'),
    url('https://db.onlinewebfonts.com/t/5bdac91b83b25ea168302ffbc40909de.ttf') format('truetype'),
    url('https://db.onlinewebfonts.com/t/5bdac91b83b25ea168302ffbc40909de.svg#Futura PT Demi') format('svg');
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white !important;
}

.mobile-fullscreen {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.column {
  display: flex;
  flex-direction: column;
}
.column-space {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: row;
}

.centered-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.centered-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.centered-left-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.centered-right-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.grow {
  flex: 1;
}

.page-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.max-full {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
}

.page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .slick-next:before, .slick-prev:before {
  color: #cdcdcd;
  height: 48px !important;
  width: 48px !important;
}

.slick-next, .slick-prev {
  height: 48px !important;
  width: 48px !important;
}

.slick-prev{
  left: -52px;
}

.slick-next{
  right: -52px;
} */
