.menu-item {
  font-size: medium;
  font-weight: 400;
}

.mobile {
  /* width: 100% !important; */
}

// @media screen and (min-width: 768px) {
//   .mobile {
//     display: none !important;
//   }
// }

@media screen and (min-width: 500px) {
  .mobile {
    display: none !important;
  }
}

.desktop {
  /* width: 100% !important; */
}

// @media screen and (max-width: 768px) {
//   .desktop {
//     display: none !important;
//   }
// }

.slider-slide {
  outline: none;
  :focus {outline: none;}
}

.mobile-top-bar {
  height: 60px;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #ededed;
}

@media screen and (min-width: 500px) {
  .mobile-top-bar {
    display: none !important;
  }
}

.justify-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.elipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-top {
  width: 100%;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.sticky-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  z-index: 2;
}

.sticky-bottom-card {
  width: 100%;
  height: 100;
  z-index: 2;
}

.card {
  border: 2px solid #69e781;
  width: 150px;
  height: 75px;
  background: black;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .card {
    flex: 0 0 auto;
    margin-right: 3px;
  }
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

.vertical-scrolling-wrapper-flexbox {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: scroll;
  // height: 100%;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }
}

.modal-title {
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}

.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-between {
  justify-content: space-between;
}

.price-range-label {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}

.justify-content {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
}

.mobile-logo {
  width: 97px;
  height: 31px;
  object-fit: contain;
  margin: auto;
}
