.root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #f7f8fc;
}

.title1 {
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.24;
  color: "#121212";
}
